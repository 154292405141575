import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button, { ButtonVariant } from "../components/Button";
import Layout from "../components/Layout";
import { useLayout } from "../components/Layout/layout-context";
import List from "../components/List";
import Quote from "../components/Quote";
import Section from "../components/Section";

const Refondre = () => {
  const [setShowModal] = useLayout();

  return (
    <Layout
      title="Refondre mon site web"
      metaTitle="Refonte de site internet"
      description="Nous travaillons à partir de l'existant de votre site en repensant le contenu, la structure, le graphisme en vue d'améliorer l'expérience client pour développer votre activité"
    >
      <p className="mt-main">
        Nos prestations de refonte consistent à travailler à partir de votre
        site existant en repensant l'expérience du client selon les objectifs
        pré-définis ensemble.
      </p>

      <Section title="🎨 Besoin d'une refonte ?">
        <p className="mt-main">
          Après une première analyse de votre situation par rapport au marché
          actuel, plusieurs points-clef vous seront proposés pour dynamiser
          votre marque et valoriser votre offre.
        </p>
        <List
          data={[
            {
              title: "🎯 Repenser le parcours",
              text: `À l’aide de la palette d’outils et de méthodes qui nous sont propres,
          nous améliorons significativement les éléments déterminants dans
          l’expérience des internautes sur votre site. De nombreux tests et
          indicateurs nous permettent de suivre en temps réel l’impact de nos
          transformations.`,
            },
            {
              title: `🎯 Éléments de réassurance`,
              text: `
          Nous renforçons l’efficacité des points qui vont rassurer le
          consommateur et l’inciter à passer à l’achat ou à la prise de contact.`,
            },
            {
              title: `🎯 Travail des pages produits`,
              text: `Nous travaillons les contenus et le ton à adopter pour s’adresser à
          votre audience. Nous faisons appel à des outils exclusifs développés
          en interne comme des ateliers d’UX writing. L’ensemble de l’équipe est
          impliquée pour avoir une vision globale du projet.`,
            },
            {
              title: `🎯 Travail du méga menu`,
              text: `Notre équipe s’attelle à une actualisation des sections structurant
          votre offre commerciale et réalise ainsi une production graphique sur
          mesure.`,
            },
          ]}
        />
        <Button
          variant={ButtonVariant.primary}
          text="Refondre mon site"
          onClick={() => setShowModal(true)}
          className="mx-auto mt-main"
        />
      </Section>

      <Section title="Ils nous ont fait confiance  pour cette prestation :">
        <div className="text-center mt-main">
          <StaticImage
            src="../images/logos/cadeau-maestro.jpg"
            alt="logo cadeau,maestro"
            height={128}
            layout="constrained"
          />
        </div>

        <Quote
          className="mt-main"
          picture={
            <StaticImage
              src="../images/photos/sylvain.png"
              alt="photo de Sylvain"
              className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
            />
          }
          nameAndTitle={
            <p>
              Sylvain Bruyère
              <br />
              PDG de Cadeau Maestro
            </p>
          }
          quote={
            <p className="italic">
              “Donut Panic a fait un travail remarquable dans le cadre de la
              refonte de notre site de e-commerce www.cadeau-maestro.com
              (Prestashop, + 3M€, 300k clients).
              <br />
              <br />
              C’était indispensable pour nous d’avoir un 2ème œil sur le travail
              réalisé sur cette refonte, et son avis a été précieux. Quentin a
              su avoir une écoute bienveillante et intelligente de nos
              problématiques commerciales et de fidélisation, les prendre en
              compte ainsi que les spécificités de notre métier, et travailler
              de concert avec nous pour imaginer un outil plus efficace et plus
              simple à utiliser pour nos clients. De nombreuses reco ont déjà
              été mises en ligne, et d’autres sont en cours.
              <br />
              <br />
              Bref, merci à Donut Panic pour ce travail qui nous aidera
              clairement à mieux répondre à nos enjeux actuels.”
            </p>
          }
        />
        <Button
          variant={ButtonVariant.secondary}
          text="Voir l'UX case"
          to="/client/cadeau-maestro"
          className="mx-auto mt-main"
        />
      </Section>
    </Layout>
  );
};

export default Refondre;
